import { InformationCircleIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { json } from "stream/consumers";
import useStore from "../store/store";
import { IEmployee } from "../types/employee";
import dateToLocaleString from "../utils/dateToLocaleString";
import dialogPolyfill from "../utils/diaglog-polyfill";

const SendInviteModal = () => {
  const {
    employees,
    inviteEmployees,
    activeDate,
    sollicitations,
    activeShift,
  } = useStore();

  const [invitedEmployees, setInvitedEmployees] = useState<IEmployee[]>([]);

  useEffect(() => {
    var dialog = document.querySelector("#sendInviteModal");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activeDate]);

  const submitForm = () => {
    if (inviteEmployees.length === 0) return;
    inviteEmployees(activeDate, invitedEmployees);
  };

  const formatTooltipData = (dates: string[]): string => {
    if (dates) {
      let outputString = "<ul>";

      outputString += dates.map((date) => {
        const dateTime = date.split(" ");
        return (
          "<li>" +
          dateToLocaleString(dateTime[0]) +
          " om " +
          dateTime[1] +
          "</li>"
        );
      });

      outputString += "</ul>";

      return outputString.replaceAll(",", "");
    }
    return "";
  };

  return (
    <dialog
      id="sendInviteModal"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">Medewerkers uitnodigen</h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("sendInviteModal")?.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <div className="flex justify-start items-center">
        <p>
          {activeDate?.eventDate !== undefined ? activeDate?.eventDate : ""}
        </p>
        <InformationCircleIcon
          height={20}
          className="ml-2 cursor-pointer"
          data-for="sendDates"
          data-html={true}
          data-tip={
            "Uitnodigingen verzonden op:" +
            formatTooltipData(
              activeDate?.invitesSendAt !== undefined
                ? activeDate?.invitesSendAt
                : []
            )
          }
        ></InformationCircleIcon>
      </div>

      <div className="flex justify-start items-start flex-col pt-6">
        <div className="flex">
          <button
            className="btn-secondary mr-4"
            name="all"
            id="all"
            onClick={() => {
              const employeesList = document.querySelectorAll(
                "input[type='checkbox'].employeeInvitationModalItem"
              );

              employeesList.forEach((emp) => {
                //@ts-ignore
                emp.checked = true;
              });

              setInvitedEmployees(employees);

              console.log(employees);
            }}
          >
            Alle
          </button>
          <button
            className="btn-secondary"
            name="all"
            id="all"
            onClick={() => {
              const employeesList = document.querySelectorAll(
                "input[type='checkbox'].employeeInvitationModalItem"
              );

              employeesList.forEach((emp) => {
                //@ts-ignore
                emp.checked = false;
              });

              setInvitedEmployees([]);

              console.log([]);
            }}
          >
            Geen
          </button>
          <button
            className="btn-secondary ml-4"
            name="all"
            id="all"
            onClick={() => {
              const notSollicitated = employees.filter(
                (emp) =>
                  sollicitations.filter(
                    (soll) =>
                      soll.employeeId === emp._id &&
                      activeDate?._id === soll.dateId
                  ).length === 0
              );
              setInvitedEmployees(notSollicitated);

              // Check all the employees that have not sollicitated
              if (notSollicitated.length === 0) {
                const employeesList = document.querySelectorAll(
                  "input[type='checkbox'].employeeInvitationModalItem"
                );

                employeesList.forEach((emp) => {
                  //@ts-ignore
                  emp.checked = false;
                });
              }
              notSollicitated.map((notSoll) => {
                const employee = document.getElementById(notSoll._id!);
                if (employee === null) return;
                //@ts-ignore
                employee.checked = true;
              });
            }}
          >
            Nog niet gereageerd
          </button>
        </div>

        <hr className="w-full mt-6" />

        <form method="dialog" onSubmit={submitForm} className="w-full">
          <div className="overflow-y-auto max-h-80">
            <ul className="w-full mt-6 mb-6">
              {employees.map((emp) => {
                return (
                  <li
                    key={emp._id}
                    className="flex justify-start items-center mb-3 ml-1"
                  >
                    <input
                      className="my-1 px-0 border-2 rounded-sm mr-3 employeeInvitationModalItem"
                      type="checkbox"
                      name={emp._id}
                      id={emp._id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          console.log("add");
                          const invitedEmployee = employees.find(
                            (invEmp) => invEmp._id === emp._id
                          );
                          if (invitedEmployee === undefined) return;
                          setInvitedEmployees(
                            invitedEmployees.concat(invitedEmployee)
                          );
                        } else {
                          console.log("remove");
                          const notInvitedEmployee = employees.find(
                            (invEmp) => invEmp._id === emp._id
                          );
                          if (notInvitedEmployee === undefined) return;
                          setInvitedEmployees(
                            invitedEmployees.filter((invEmp) => {
                              const invited = JSON.stringify(invEmp);
                              const notInvited =
                                JSON.stringify(notInvitedEmployee);

                              return invited !== notInvited;
                            })
                          );
                        }
                      }}
                    />
                    <label htmlFor={emp._id}>
                      {emp.firstName} {emp.lastName}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex justify-end w-full">
            <button
              className="btn-primary disabled:opacity-50"
              type="submit"
              disabled={invitedEmployees.length === 0}
            >
              Uitnodigingen verzenden
            </button>
          </div>
        </form>
      </div>
      <ReactTooltip
        id="sendDates"
        place="right"
        type="dark"
        effect="solid"
        //multiline={true}
        html={true}
      />
    </dialog>
  );
};

export default SendInviteModal;
