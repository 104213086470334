import type { NextPage } from "next";

import AddShiftModal from "../components/AddShiftModal";
import AddTabModal from "../components/AddTabModal";
import ArchiveTabModal from "../components/ArchiveTabModal";
import DatepickerModal from "../components/DatepickerModal";
import DuplicateModal from "../components/DuplicateModal";
import EventDate from "../components/EventDate";
import EventListBar from "../components/EventListBar";
import EventSubHeader from "../components/EventSubHeader";
import LateEditErrorModal from "../components/LateEditErrorModal";
import Layout from "../components/Layout";
import RenameTabModal from "../components/RenameTabModal";
import SendInviteModal from "../components/SendInviteModal";
import ShiftDeleteNotPossibelModal from "../components/ShiftDeleteNotPossibleModal";
import Sollications from "../components/Sollicitations";
import Spinner from "../components/Spinner";
import UpdateShiftInfoModal from "../components/UpdateShiftInfoModal";
import useStore from "../store/store";

const Home: NextPage = () => {
  const { activeEvent, showAllocateEmployeeAsside, dataLoaded } = useStore();

  if (!dataLoaded) {
    return (
      <Layout currentPage="planning">
        <Spinner></Spinner>
      </Layout>
    );
  }

  if (dataLoaded && !activeEvent) {
    return (
      <Layout currentPage="planning">
        <div className="flex w-full justify-center items-center">
          <div className="mt-[10vh] flex flex-col justify-center items-center">
            <p>
              We vonden nog geen tabbladen voor jou...<br></br> klik hieronder
              om je eerste toe te voegen
            </p>
            <div>
              <button
                className="btn-primary mt-5 mx-auto"
                onClick={() => {
                  //@ts-ignore
                  document.getElementById("addTab")?.showModal();
                }}
              >
                Voeg tabblad toe
              </button>
            </div>
          </div>
        </div>
        <AddTabModal></AddTabModal>
      </Layout>
    );
  }
  return (
    <Layout currentPage="planning">
      <div className="flex  min-h-[calc(100%-72px)]">
        <Sollications></Sollications>
        <div className="flex-1">
          <EventListBar></EventListBar>
          {activeEvent && (
            <>
              <EventSubHeader></EventSubHeader>
              <EventDate></EventDate>
            </>
          )}
          <AddTabModal></AddTabModal>
          <ArchiveTabModal></ArchiveTabModal>
          <RenameTabModal></RenameTabModal>
          <UpdateShiftInfoModal></UpdateShiftInfoModal>
          <ShiftDeleteNotPossibelModal></ShiftDeleteNotPossibelModal>
          <SendInviteModal></SendInviteModal>
          <AddShiftModal></AddShiftModal>
          <LateEditErrorModal></LateEditErrorModal>
          <DatepickerModal></DatepickerModal>
          <DuplicateModal></DuplicateModal>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
