import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import dialogPolyfill from "../utils/diaglog-polyfill";

const LateEditErrorModal = () => {
  useEffect(() => {
    var dialog = document.querySelector("#lateEditError");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  return (
    <dialog
      id="lateEditError"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">
          PLANNEN IN HET VERLEDEN NIET MOGELIJK
        </h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("lateEditError")?.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <div className="flex justify-center items-center flex-col">
        <ExclamationCircleIcon className="text-red h-64 mt-7 mb-7"></ExclamationCircleIcon>
        <p className="text-center">
          Het is niet mogelijk om een shift toe te voegen in het verleden.
        </p>
      </div>
    </dialog>
  );
};

export default LateEditErrorModal;
