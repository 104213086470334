import { useEffect, useState } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

const RenameTabModal = ({}) => {
  const { activeEvent, editEvent } = useStore();
  const [name, setName] = useState(activeEvent?.name || "");
  const [eventDescription, setEventDescription] = useState(activeEvent?.description || "");
  const submitForm = () => {
    editEvent(name, eventDescription);
  };
  useEffect(() => {
    var dialog = document.querySelector("#renameTabModal");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  useEffect(() => {
    setName(activeEvent?.name || "");
  }, [activeEvent]);
  return (
    <dialog
      id="renameTabModal"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <form method="dialog" onSubmit={submitForm}>
        <div className="flex items-center w-full justify-between">
          <h3 className="uppercase font-bold">Tabblad aanpassen</h3>
          <button
            className="text-3xl"
            onClick={() => {
              //@ts-ignore
              document.querySelector("#renameTabModal")?.close();
            }}
            type="reset"
          >
            &times;
          </button>
        </div>

        <div className="flex flex-col pt-6">
          <label htmlFor="name">Naam</label>
          <input
            className="form-input bg-grey border-none rounded-md"
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={50}
          />
        </div>

        <div className="flex flex-col pt-2">
          <label htmlFor="tabDescription">Tab beschrijving</label>
          <textarea
            className="form-input bg-grey border-none rounded-md resize-none"
            name="tabDescription"
            id="tabDescription"
            cols={30}
            rows={5}
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="flex justify-end pt-4">
          <button className="btn-primary mr-4">Opslaan</button>
          <button
            className="btn-secondary"
            type="reset"
            onClick={() => {
              //@ts-ignore
              document.querySelector("#renameTabModal")?.close();
            }}
          >
            Annuleren
          </button>
        </div>
      </form>
    </dialog>
  );
};
export default RenameTabModal;
