const Legend = () => {
  return (
    <div className="flex-col sticky bottom-4 z-0 bg-white rounded-lg p-4 mt-4">
      <h3 className="text-lg font-bold mb-4">Legende</h3>
      <div className="flex items-center mb-4">
        <div className="rounded-full !w-[14px] !h-[14px] bg-orange mr-2"></div>
        <p className="w-[120px]">Bevestigingsmail nog niet verzonden</p>
      </div>
      <div className="flex items-center mb-4">
        <div className="rounded-full !w-[14px] !h-[14px] bg-blue mr-2"></div>
        <p className="w-[120px]">Bevestigingsmail verzonden</p>
      </div>
      <div className="flex items-center mb-4">
        <div className="rounded-full !w-[14px] !h-[14px] bg-bright-green mr-2"></div>
        <p className="w-[120px]">Bevestigd</p>
      </div>
      <div className="flex items-center mb-4">
        <div className="rounded-full !w-[14px] !h-[14px] bg-red mr-2"></div>
        <p className="w-[120px]">Afgewezen</p>
      </div>
    </div>
  );
};

export default Legend;
