import { FormEvent, useEffect, useState } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

const AddTabModal = () => {
  const { addEvent, setActiveEvent, setLoading } = useStore();

  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  useEffect(() => {
    var dialog = document.querySelector("#addTab");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  const submitForm = async (e: FormEvent) => {
    setLoading(true);
    addEvent(eventName, eventDescription);
    setEventName("");
    setEventDescription("");
  };

  return (
    <dialog
      id="addTab"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">Tab toevoegen</h3>
        <button
          className="text-3xl ml-2"
          onClick={() => {
            setEventName("");
            setEventDescription("");
            //@ts-ignore
            document.getElementById("addTab")!.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>
      <form method="dialog" onSubmit={submitForm}>
        <div className="flex flex-col pt-2">
          <label htmlFor="eventName">Tab naam</label>
          <input
            className="form-input bg-grey border-none rounded-md"
            type="text"
            name="eventName"
            id="eventName"
            required
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            maxLength={50}
          />
        </div>

        <div className="flex flex-col pt-2">
          <label htmlFor="tabDescription">Tab beschrijving</label>
          <textarea
            className="form-input bg-grey border-none rounded-md resize-none"
            name="tabDescription"
            id="tabDescription"
            cols={30}
            rows={5}
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="flex justify-end pt-4">
          <button className="btn-primary mr-4">Opslaan</button>
          <button
            className="btn-secondary"
            onClick={() => {
              setEventName("");
              setEventDescription("");
              //@ts-ignore
              document.getElementById("addTab")!.close();
            }}
            type="reset"
          >
            Annuleren
          </button>
        </div>
      </form>
    </dialog>
  );
};

export default AddTabModal;
