import { useCallback, useEffect } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

const ArchiveTabModal = ({}) => {
  const { deleteEvent } = useStore();

  const submitForm = useCallback(() => {
    deleteEvent();
  }, [deleteEvent]);
  useEffect(() => {
    var dialog = document.querySelector("#archiveTabModal");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);
  return (
    <dialog
      id="archiveTabModal"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <form method="dialog" onSubmit={submitForm}>
        <div className="flex items-center w-full justify-between">
          <h3 className="uppercase font-bold">Wilt u deze tab archiveren?</h3>

          <button
            className="text-3xl"
            onClick={() => {
              //@ts-ignore
              document.querySelector("#archiveTabModal")?.close();
            }}
            type="reset"
          >
            &times;
          </button>
        </div>

        <div className="flex justify-end pt-6">
          <button className="btn-primary mr-4">Ja</button>
          <button
            className="btn-secondary"
            type="reset"
            onClick={() => {
              //@ts-ignore
              document.querySelector("#archiveTabModal")?.close();
            }}
          >
            Neen
          </button>
        </div>
      </form>
    </dialog>
  );
};
export default ArchiveTabModal;
