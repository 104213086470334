const phoneNumberParser = (currentPhoneNumber: string | number): string => {
  const currentPhoneNumberString = currentPhoneNumber
    .toString()
    .split(".")
    .join("")
    .split("/")
    .join("")
    .split(" ")
    .join("");

  if (currentPhoneNumberString.startsWith("0032")) {
    return `32${currentPhoneNumberString.substring(4)}`;
  } else if (currentPhoneNumberString.startsWith("0")) {
    return `32${currentPhoneNumberString.substring(1)}`;
  } else if (currentPhoneNumberString.startsWith("+32")) {
    return `32${currentPhoneNumberString.substring(3)}`;
  } else if (currentPhoneNumberString.startsWith("4")) {
    return `32${currentPhoneNumberString}`;
  }

  return currentPhoneNumberString;
};

export default phoneNumberParser;
