import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { FormEvent, useEffect, useState } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

const ShiftDeleteNotPossibelModal = () => {
  useEffect(() => {
    var dialog = document.querySelector("#deleteNotPossible");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  return (
    <dialog
      id="deleteNotPossible"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">Shift verwijderen niet mogelijk</h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("deleteNotPossible")?.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <div className="flex justify-center items-center flex-col">
        <ExclamationCircleIcon className="text-red h-64 mt-7"></ExclamationCircleIcon>
        <p className="text-center text-blue mt-7">
          De shift die u probeert te verwijderen bevat nog medewerkers.
        </p>
        <p className="text-center text-blue">
          Verwijder de medewerkers alvorens de shift te verwijderen.
        </p>
      </div>
    </dialog>
  );
};

export default ShiftDeleteNotPossibelModal;
