import { FormEvent, useEffect, useState } from "react";
import useStore from "../store/store";

const EventSubHeader = () => {
  const [eventDate, setEventDate] = useState("");
  const [showAddEvent, setAddEvent] = useState(false);

  const { addEventDate, activeEvent } = useStore();

  useEffect(() => {
    if (activeEvent === null || activeEvent === undefined) {
      setAddEvent(false);
    } else {
      setAddEvent(true);
    }
  }, [activeEvent]);

  return (
    <div className="container mx-auto  flex justify-end mt-5">
      <button
        className="btn-blue mr-2"
        //@ts-ignore
        onClick={() => document.getElementById("archiveTabModal").showModal()}
      >
        Archiveren
      </button>
      <button
        className="btn-blue mr-2"
        //@ts-ignore
        onClick={() => document.getElementById("renameTabModal").showModal()}
      >
        Wijzigen
      </button>

      {showAddEvent && (
        <button
          className="btn-blue py-3"
          onClick={(e) =>
            // @ts-ignore
            document.getElementById("selectDateModal")?.showModal()
          }
        >
          Datum toevoegen
        </button>
      )}
    </div>
  );
};

export default EventSubHeader;
