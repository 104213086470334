import useStore from "../store/store";
import { XCircleIcon, PlusIcon, ChatAltIcon } from "@heroicons/react/solid";
import { IShift } from "../types/shift";
import { IEventDateType } from "../types/eventDate";
import { useEffect, useState } from "react";
import { IAllocation } from "../types/allocation";
import { CheckCircleIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import Link from "next/link";
import ContactModal from "./ContactModal";
import dayjs from "dayjs";

const Allocations = ({
  shift,
  date,
}: {
  shift: IShift;
  date: IEventDateType;
}) => {
  const {
    setActiveShift,
    setEmployeeAmount,
    removeAllocation,
    changeAllocationStatus,
    allocations,
    employees,
    activeShift,
    shifts,
    setLoading,
    setActiveDate,
  } = useStore();
  const [allocationSlots, setAllocationSlots] = useState<Array<IAllocation>>(
    []
  );
  const [contactModal, setContactModal] = useState({
    employeeId: "",
    shiftId: "",
  });
  const [activeShiftCopy, setActiveShiftCopy] = useState("");

  useEffect(() => {
    setAllocationSlots(
      allocations.filter((allocation) => allocation.shiftId === shift._id)
    );
  }, [allocations, shift._id]);

  return (
    <div className="flex flex-wrap gap-2">
      {[...Array(shift.employeeAmount)].map((_, index) => {
        return (
          <div
            onClick={() => {
              if (allocationSlots[index] !== undefined) return;
              setActiveDate(date);
              setActiveShift({ ...shift, slot: index });
              setActiveShiftCopy(JSON.stringify({ ...shift, slot: index }));
            }}
            key={index}
            className={`relative px-2 flex justify-center items-center min-w-[110px] h-[41px] rounded-lg ${
              allocationSlots[index] === undefined ? "bg-grey" : ""
            } ${
              allocationSlots[index]?.status === "pending"
                ? "bg-orange text-white"
                : ""
            } ${
              allocationSlots[index]?.status === "not-available"
                ? "bg-red text-white"
                : ""
            } ${
              allocationSlots[index]?.status === "available"
                ? "bg-bright-green text-white"
                : ""
            } ${
              allocationSlots[index]?.status === "verificationSent"
                ? "bg-blue text-white"
                : ""
            } ${
              activeShift?.slot === index &&
              shift.eventDateId === activeShift.eventDateId &&
              shift._id === activeShift._id
                ? "outline-2 outline-black outline"
                : ""
            } cursor-pointer text-center`}
          >
            {allocationSlots[index] === undefined && (
              <p className="text-black">
                Kies <br /> medewerker
              </p>
            )}

            {allocationSlots[index] !== undefined &&
              employees.find(
                (employee) => employee._id === allocationSlots[index].employeeId
              ) && (
                <>
                  <p>
                    {
                      employees.find(
                        (employee) =>
                          employee._id === allocationSlots[index].employeeId
                      )?.firstName
                    }
                    <br />
                    {
                      employees.find(
                        (employee) =>
                          employee._id === allocationSlots[index].employeeId
                      )?.lastName
                    }
                  </p>

                  <button
                    className="absolute top-1 left-1"
                    data-for="buttonTitle"
                    data-tip="Contact opties"
                    onClick={(e) => {
                      e.stopPropagation();
                      setContactModal({
                        employeeId: allocationSlots[index].employeeId,
                        shiftId: allocationSlots[index].shiftId,
                      });
                    }}
                  >
                    <ChatAltIcon height={15}></ChatAltIcon>
                  </button>

                  <ContactModal
                    allocation={allocationSlots[index]}
                    open={
                      contactModal.employeeId ===
                        allocationSlots[index].employeeId &&
                      contactModal.shiftId === allocationSlots[index].shiftId
                    }
                    close={() =>
                      setContactModal({ employeeId: "", shiftId: "" })
                    }
                    left=""
                    top=""
                    position="bottom"
                  ></ContactModal>
                </>
              )}

            {allocationSlots[index] !== undefined &&
              employees.find(
                (employee) => employee._id === allocationSlots[index].employeeId
              ) === undefined && (
                <>
                  <p>
                    {allocationSlots[index].employeeFirstName}
                    <br />
                    {allocationSlots[index].employeeLastName}
                  </p>

                  <button
                    className="absolute top-1 left-1"
                    data-for="buttonTitle"
                    data-tip="Contact opties"
                    onClick={(e) => {
                      e.stopPropagation();
                      setContactModal({
                        employeeId: allocationSlots[index].employeeId,
                        shiftId: allocationSlots[index].shiftId,
                      });
                    }}
                  >
                    <ChatAltIcon height={15}></ChatAltIcon>
                  </button>

                  <ContactModal
                    allocation={allocationSlots[index]}
                    open={
                      contactModal.employeeId ===
                        allocationSlots[index].employeeId &&
                      contactModal.shiftId === allocationSlots[index].shiftId
                    }
                    close={() =>
                      setContactModal({ employeeId: "", shiftId: "" })
                    }
                    left=""
                    top=""
                  ></ContactModal>
                </>
              )}

            <button
              className="absolute top-1 right-1"
              data-for="buttonTitle"
              data-tip={
                allocationSlots[index] === undefined
                  ? "Verwijderen"
                  : "Annuleren en verwijderen"
              }
              onClick={(e) => {
                e.stopPropagation();

                if (allocationSlots[index] === undefined) {
                  setLoading(true);
                  setEmployeeAmount(
                    shift,
                    Math.max(shift.employeeAmount - 1, 1)
                  );
                } else {
                  setLoading(true);
                  removeAllocation(allocationSlots[index]);
                }
              }}
            >
              <XCircleIcon height={15}></XCircleIcon>
            </button>

            {allocationSlots[index] !== undefined &&
              allocationSlots[index]?.status !== "available" &&
              allocationSlots[index]?.status !== "not-available" && (
                <button
                  className="absolute bottom-1 right-1"
                  data-for="buttonTitle"
                  data-tip="Manueel bevestigen"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (shift === undefined) return;

                    // check if shift is in the past
                    if (
                      new Date(date.eventDate).setHours(0, 0, 0, 0) <
                      new Date().setHours(0, 0, 0, 0)
                    ) {
                      // @ts-ignore
                      document.getElementById("lateEditError").showModal();
                      return;
                    }

                    // check if it is in less than 30 min
                    if (
                      dayjs().add(30, "minutes") >
                      dayjs(`${date.eventDate}T${shift.shiftStart}:00`)
                    ) {
                      // @ts-ignore
                      document.getElementById("lateEditError").showModal();
                      return;
                    }

                    changeAllocationStatus(allocationSlots[index], "available");
                  }}
                >
                  <CheckCircleIcon height={15}></CheckCircleIcon>
                </button>
              )}
          </div>
        );
      })}
      <button
        className="border  h-[41px] w-[41px] border-solid border-blue flex justify-center items-center rounded-lg"
        data-for="buttonTitle"
        data-tip="Slot voor medewerker toevoegen"
        onClick={() => {
          setLoading(true);
          setEmployeeAmount(shift, shift.employeeAmount + 1);
        }}
      >
        <PlusIcon height={20}></PlusIcon>
      </button>
      <ReactTooltip
        id="buttonTitle"
        place="right"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </div>
  );
};

export default Allocations;
