import { useEffect, useState } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

const UpdateShiftInfoModal = ({}) => {
  const { activeShiftForInfoModal, updateShiftInfo } = useStore();
  const [shiftInfo, setShiftInfo] = useState(activeShiftForInfoModal?.shiftInfo || "");
  const submitForm = () => {
    updateShiftInfo(shiftInfo);
  };
  useEffect(() => {
    var dialog = document.querySelector("#updateShiftInfoModal");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  useEffect(() => {
    setShiftInfo(activeShiftForInfoModal?.shiftInfo || "");
  }, [activeShiftForInfoModal]);
  return (
    <dialog
      id="updateShiftInfoModal"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <form method="dialog" onSubmit={submitForm}>
        <div className="flex items-center w-full justify-between">
          <h3 className="uppercase font-bold">Shift info wijzigen</h3>
          <button
            className="text-3xl"
            onClick={() => {
              //@ts-ignore
              document.querySelector("#updateShiftInfoModal")?.close();
            }}
            type="reset"
          >
            &times;
          </button>
        </div>

        <div className="flex flex-col pt-6">
          <label htmlFor="shiftInfo"></label>
          <textarea
            name="shiftInfo"
            className="form-input bg-grey border-none rounded-md resize-none"
            id="shiftInfo"
            rows={4}
            value={shiftInfo}
            onChange={(e) => setShiftInfo(e.target.value)}
          />
        </div>
        <div className="flex justify-end pt-4">
          <button className="btn-primary mr-4">Opslaan</button>
          <button
            className="btn-secondary"
            type="reset"
            onClick={() => {
              //@ts-ignore
              document.querySelector("#updateShiftInfoModal")?.close();
            }}
          >
            Annuleren
          </button>
        </div>
      </form>
    </dialog>
  );
};
export default UpdateShiftInfoModal;
