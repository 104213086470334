import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { FormEvent, useEffect, useState } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

type AlreadyPlannedModalPropsType = {
  employeeName: string;
};

const AlreadyPlannedModal = ({
  employeeName,
}: AlreadyPlannedModalPropsType) => {
  useEffect(() => {
    var dialog = document.querySelector("#alreadyPlanned");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  return (
    <dialog
      id="alreadyPlanned"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">
          Medewerker is al ingepland op deze dag
        </h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("alreadyPlanned")?.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <div className="flex justify-center items-center flex-col">
        <ExclamationCircleIcon className="text-red h-64 mt-7"></ExclamationCircleIcon>
        <p className="text-center text-blue mt-7">
          <span className="font-bold">{employeeName}</span> staat al ingepland
          op de geselecteerde dag.
        </p>
        <p className="text-center text-blue">
          Verwijder de medewerker eerst uit de andere shift om deze hier te
          kunnen toevoegen.
        </p>
      </div>
    </dialog>
  );
};

export default AlreadyPlannedModal;
