import { FormEvent, useEffect, useState } from "react";
import dialogPolyfill from "../utils/diaglog-polyfill";
import { format } from "date-fns";
import { ClassNames, DayPicker } from "react-day-picker";
import styles from "react-day-picker/dist/style.css";
import useStore from "../store/store";
import dayjs from "dayjs";
import nl from 'date-fns/locale/nl-BE';

const DatepickerModal = () => {
  const classNames: ClassNames = {
    ...styles,
    head: "text-red",
  };

  const { activeEvent, addEventDate, setLoading, eventDates, addDate } =
    useStore();

  const [date, setDate] = useState<Date>();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setError("");
    setDate(undefined);
    var dialog = document.querySelector("#selectDateModal");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();

    if (date === undefined) return;
    const parsedDate = dayjs(date).format("YYYY-MM-DD");
    setLoading(true);

    // check if date is in past
    if (dayjs(date).isBefore(dayjs(), "day")) {
      setError("Datum ligt in het verleden");
      setLoading(false);

      return;
    }
    if (
      eventDates.find(
        (ed) =>
          ed.eventId === activeEvent?._id &&
          ed.eventDate === parsedDate &&
          ed.active === true
      ) === undefined
    ) {
      try {
        addDate(activeEvent?._id || "", parsedDate);
        setError("");
        setDate(undefined);
        //@ts-ignore
        document.getElementById("selectDateModal")!.close();
      } catch (e) {
        console.log(e);
      }
    } else {
      setError("Deze datum bestaat al in de planning");
      setLoading(false);
    }
  };

  return (
    <dialog
      id="selectDateModal"
      className="border-none rounded-xl py-5 px-10 min-w-[400px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">Datum toevoegen</h3>
        <button
          className="text-3xl"
          onClick={() => {
            //@ts-ignore
            document.getElementById("selectDateModal")!.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <form
        method="dialog"
        onSubmit={submitForm}
        className="flex-col items-center justify-center "
      >
        <DayPicker
          locale={nl}
          classNames={classNames}
          mode="single"
          selected={date}
          onSelect={setDate}
          footer={error}
        />
        <div className="flex justify-end pt-4">
          <button className="btn-primary">Toevoegen</button>
        </div>
      </form>
    </dialog>
  );
};

export default DatepickerModal;
