const dateToLocaleString = (dateString: string) => {
  const dateLocaleString = new Date(dateString).toLocaleDateString("nl-BE", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return dateLocaleString;
};

export default dateToLocaleString;
