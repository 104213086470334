import {
  InformationCircleIcon,
  MailIcon,
  PhoneIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import {
  ChatAltIcon,
  CursorClickIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import employees from "../pages/api/employees";
import useStore from "../store/store";
import { IAllocatable } from "../types/allocatable";
import { ISollicitation } from "../types/sollicitation";
import dateToLocaleString from "../utils/dateToLocaleString";
import ReactTooltip from "react-tooltip";
import AlreadyPlannedModal from "./AlreadyPlannedModal";
import Link from "next/link";
import ContactModal from "./ContactModal";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Legend from "./Legend";
import { formatMyCompany } from "../utils/company";
dayjs.extend(relativeTime);

type filterTypes =
  | "all"
  | "fully-available"
  | "partialy-available"
  | "no-response"
  | "planned"
  | "not-available";
type filterSelectType = {
  value: filterTypes;
  label: string;
};

const Sollications = () => {
  const {
    sollicitations,
    employees,
    myCompany,
    activeShift,
    activeEvent,
    eventDates,
    allocations,
    activeDate,
    shifts,
    setActiveShift,
    addAllocation,
    setLoading,
  } = useStore();

  const [clickedUser, setClickedUser] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeesShown, setEmployeesShown] = useState(false);
  const [filterParam, setFilterParam] =
    useState<filterTypes>("fully-available");
  const [filteredAllocatables, setFilteredAllocatables] = useState<
    Array<IAllocatable | null>
  >([]);

  const filterOptions: filterSelectType[] = [
    { value: "all", label: "Alle medewerkers" },
    { value: "fully-available", label: "Volledig beschikbaar" },
    { value: "partialy-available", label: "Deels beschikbaar" },
    { value: "not-available", label: "Niet beschikbaar" },
    { value: "no-response", label: "Niet gereageerd" },
    { value: "planned", label: "Reeds ingepland" },
  ];

  const filterAllocatables = (
    availability: string
  ): Array<IAllocatable | null> => {
    return sollicitations.map((soll) => {
      const employee = employees.find(
        (x) =>
          x._id === soll.employeeId &&
          soll.availabilityStatus === availability &&
          soll.dateId === activeShift?.eventDateId
      );
      if (!employee) return null;

      return {
        employeeId: employee._id!,
        employeeEmail: employee.email,
        employeePhone: employee.phone,
        employeeFirstName: employee.firstName,
        employeeLastName: employee.lastName,
        employeeRrnr: employee.registerNumber,
        sollicitationId: soll._id,
        dateId: soll.dateId,
        availableFrom: soll.availableFrom,
        availableTo: soll.availableTo,
        note: soll.notes,
      };
    });
  };

  useEffect(() => {
    switch (filterParam) {
      case "all":
        setFilteredAllocatables(
          employees.map((emp) => {
            return {
              employeeId: emp._id!,
              employeeEmail: emp.email,
              employeePhone: emp.phone,
              employeeFirstName: emp.firstName,
              employeeLastName: emp.lastName,
              employeeRrnr: emp.registerNumber,
            };
          })
        );

        break;

      case "fully-available":
        setFilteredAllocatables(filterAllocatables("fully-available"));

        break;

      case "partialy-available":
        setFilteredAllocatables(filterAllocatables("partialy-available"));

        break;

      case "not-available":
        setFilteredAllocatables(filterAllocatables("not-available"));

        break;

      case "planned":
        setFilteredAllocatables(
          allocations
            .filter((x) => x.shiftId === activeShift?._id?.toString())
            .map((alloc) => {
              return {
                employeeId: alloc.employeeId,
                employeeEmail: alloc.employeeEmail,
                employeePhone:
                  employees.filter((emp) => emp._id === alloc.employeeId)
                    .length !== 0
                    ? employees.filter((emp) => emp._id === alloc.employeeId)[0]
                        .phone
                    : alloc.employeePhone,
                employeeFirstName:
                  employees.filter((emp) => emp._id === alloc.employeeId)
                    .length !== 0
                    ? employees.filter((emp) => emp._id === alloc.employeeId)[0]
                        ?.firstName
                    : alloc.employeeFirstName,
                employeeLastName:
                  employees.filter((emp) => emp._id === alloc.employeeId)
                    .length !== 0
                    ? employees.filter((emp) => emp._id === alloc.employeeId)[0]
                        ?.lastName
                    : alloc.employeeLastName,
                employeeRrnr:
                  employees.filter((emp) => emp._id === alloc.employeeId)
                    .length !== 0
                    ? employees.filter((emp) => emp._id === alloc.employeeId)[0]
                        ?.registerNumber
                    : alloc.employeeRrnr,
                dateId: alloc.dateId,
              };
            })
        );

        break;

      case "no-response":
        setFilteredAllocatables(
          employees.map((emp) => {
            const filteredAllocations = sollicitations.filter(
              (soll) =>
                soll.employeeId === emp._id &&
                soll.dateId === activeShift?.eventDateId
            );
            if (filteredAllocations.length !== 0) return null;

            return {
              employeeId: emp._id!,
              employeeEmail: emp.email,
              employeePhone: emp.phone,
              employeeFirstName: emp.firstName,
              employeeLastName: emp.lastName,
              employeeRrnr: emp.registerNumber,
            };
          })
        );

        break;
    }
  }, [filterParam, employees, activeShift, allocations]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [filteredAllocatables]);

  // if (!activeShift) return null;

  return (
    <aside className="w-[220px] bg-blue flex relative flex-col text-left text-xs p-3">
      <div className="h-full flex flex-col justify-between">
        <div
          className={`sticky top-3 z-10  bg-white rounded-lg ${
            activeShift ? "h-[calc(100vh-24px)]" : ""
          }`}
        >
          {!activeShift && (
            <div className="flex-col items-center justify-center font-bold text-center p-4">
              <div className="mb-2 w-full flex items-center justify-center text-blue">
                <CursorClickIcon height={30}></CursorClickIcon>
              </div>

              {eventDates.filter((x) => x.eventId === activeEvent?._id)
                .length === 0 && <p>Voeg een datum toe</p>}

              {eventDates.filter((x) => x.eventId === activeEvent?._id).length >
                0 &&
                shifts.filter((x) => x.eventId === activeEvent?._id).length ===
                  0 && <p>Voeg een shift toe</p>}

              {shifts.filter((x) => x.eventId === activeEvent?._id).length >
                0 &&
              shifts
                .filter((x) => x.eventId === activeEvent?._id)
                .map((x) => x.employeeAmount)
                .reduce((a, b) => a + b, 0) ===
                allocations.filter((x) => x.eventId === activeEvent?._id)
                  .length ? (
                <p>Alle shifts zijn ingepland!</p>
              ) : (
                shifts.filter((x) => x.eventId === activeEvent?._id).length >
                  0 && (
                  <p>
                    Selecteer een leeg slot om op te vullen door &quot;Kies
                    medewerker&quot; te selecteren
                  </p>
                )
              )}
            </div>
          )}
          {activeShift && (
            <div className="flex w-full justify-between items-start p-4">
              <h3 className="text-lg font-bold">Kies een medewerker</h3>
              <button onClick={() => setActiveShift(null)} className="text-lg">
                &times;
              </button>
            </div>
          )}

          {activeShift ? (
            <>
              <div className="shiftInfo bg-grey rounded-xl p-[10px] text-xs mx-4 mb-4">
                <p className="font-bold text-blue">Shift</p>
                <p className="text-blue">{activeShift.shiftName}</p>
                <p className="text-blue">
                  {dateToLocaleString(
                    eventDates.filter(
                      (date) => date._id === activeShift.eventDateId
                    )[0]?.eventDate
                  )}
                </p>
              </div>

              <select
                className="w-[164px] bg-light-red text-white rounded-xl border-0 text-xs font-bold p-3 mb-4  mx-4"
                name="employeefilter"
                id="employeefilter"
                value={filterParam}
                onChange={(e) => setFilterParam(e.target.value as filterTypes)}
              >
                {filterOptions.map((option, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>

              {filteredAllocatables.length !== 0 && (
                <div className="overflow-y-scroll overflow-x-hidden h-[calc(100%-245px)] pl-4">
                  {filteredAllocatables.map((alloc) => {
                    if (!alloc) return;
                    if (
                      !alloc ||
                      (allocations.find(
                        (allocation) =>
                          alloc.employeeId === allocation.employeeId &&
                          allocation.dateId === activeShift.eventDateId
                      ) &&
                        [
                          "all",
                          "fully-available",
                          "partialy-available",
                          "no-response",
                          "not-available",
                        ].includes(filterParam))
                    )
                      return;

                    return (
                      <div
                        className="text-left py-2 flex justify-between items-center w-full relative"
                        key={alloc.employeeId}
                      >
                        <ContactModal
                          allocation={alloc}
                          open={employeeId === alloc.employeeId}
                          close={() => setEmployeeId("")}
                          left="105"
                          top="8"
                          position="left"
                        ></ContactModal>
                        <div className="info">
                          {alloc.employeeFirstName} {alloc.employeeLastName}
                          {alloc.availableFrom && alloc.availableTo && (
                            <span className="block">
                              {alloc.availableFrom}-{alloc.availableTo}
                            </span>
                          )}
                        </div>
                        <div className="controls flex justify-between items-center">
                          {alloc.note && (
                            <button
                              className="text-black"
                              data-for="allocationMessage"
                              data-tip={alloc.note ? alloc.note : ""}
                            >
                              <InformationCircleIcon
                                height={20}
                              ></InformationCircleIcon>
                            </button>
                          )}

                          {filterParam !== "planned" && (
                            <button
                              className="allocate text-red cursor-pointer"
                              data-for="allocationMessage"
                              data-tip="Medewerker alloceren"
                              onClick={(e) => {
                                setClickedUser(
                                  `${alloc.employeeFirstName} ${alloc.employeeLastName}`
                                );

                                if (
                                  new Date(
                                    `${activeDate?.eventDate}T${activeShift.shiftStart}:00`
                                  ) < new Date() ||
                                  dayjs().add(30, "minutes") >=
                                    dayjs(
                                      `${activeDate?.eventDate}T${activeShift.shiftStart}:00`
                                    )
                                ) {
                                  document
                                    .getElementById("lateEditError")
                                    //@ts-ignore
                                    ?.showModal();
                                  return;
                                }

                                if (
                                  activeShift.employeeAmount >
                                  allocations.filter(
                                    (alloc) => alloc.shiftId === activeShift._id
                                  ).length
                                ) {
                                  setLoading(true);
                                  addAllocation({
                                    shiftId: activeShift._id,
                                    employeeId: alloc.employeeId,
                                    employeeFirstName: alloc.employeeFirstName,
                                    employeeLastName: alloc.employeeLastName,
                                    employeeRrnr: alloc.employeeRrnr,
                                    eventId: activeShift.eventId,
                                    company: formatMyCompany(myCompany),
                                    employeeEmail: alloc.employeeEmail,
                                    employeePhone:
                                      alloc.employeePhone.toString(),
                                    dateId: activeShift.eventDateId,
                                    date: eventDates.filter(
                                      (ed) => ed._id === activeShift.eventDateId
                                    )[0].eventDate,
                                    status: "pending",
                                    sentToBackOffice: false,
                                  });
                                }
                              }}
                            >
                              <PlusCircleIcon height={20}></PlusCircleIcon>
                            </button>
                          )}

                          <button
                            className="allocate text-red cursor-pointer"
                            data-for="allocationMessage"
                            data-tip="Contact opties"
                            onClick={(e) => {
                              const empId = alloc.employeeId;

                              if (employeeId === empId) {
                                setEmployeeId("");
                              } else {
                                setEmployeeId(alloc.employeeId);
                              }
                            }}
                          >
                            <ChatAltIcon height={20}></ChatAltIcon>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {filteredAllocatables.length === 0 && (
                <div className="px-4">
                  Geen medewerkers gevonden die voldoen aan dit criterium, pas
                  deze hierboven aan of
                  <button
                    className="inline underline"
                    onClick={() => setFilterParam("all")}
                  >
                    toon alle mederwerkers
                  </button>
                </div>
              )}
            </>
          ) : (
            <div></div>
          )}
        </div>
        {!activeShift && <Legend></Legend>}
      </div>

      <ReactTooltip
        id="allocationMessage"
        place="right"
        type="dark"
        effect="solid"
        multiline={true}
      />

      <AlreadyPlannedModal employeeName={clickedUser}></AlreadyPlannedModal>
    </aside>
  );
};

export default Sollications;
