import { useEffect, useState } from "react";
import useStore from "../store/store";
import {
  BadgeCheckIcon,
  PaperAirplaneIcon,
  PencilIcon,
  PlusCircleIcon,
  PlusIcon,
  DuplicateIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import Allocations from "./Allocations";
import dateToLocaleString from "../utils/dateToLocaleString";
import { IEventDateType } from "../types/eventDate";

const EventDate = () => {
  const {
    activeEvent,
    eventDates,
    setActiveDate,
    shifts,
    setActiveShiftForInfoModal,
    removeShift,
    inviteEmployees,
    removeDate,
    verifyEmployees,
    allocations,
    setLoading,
    setDuplicateId,
  } = useStore();

  return (
    <div className="container mx-auto mt-4 text-xs mb-16">
      {!!eventDates.filter((ed) => ed.eventId === activeEvent?._id).length && (
        <div className="flex justify-between">
          <div className="px-5 w-1/2 flex">
            <div className="flex-1">Shift</div>
            <div className="flex-1">Start</div>
            <div className="flex-1">Einde</div>
            <div className="flex-1">Pauze start</div>
            <div className="flex-1">Pauze einde</div>
            <div className="flex-1">#</div>
          </div>
          <div className="w-1/2 flex justify-between">
            <div>Medewerkers</div>
          </div>
        </div>
      )}

      {eventDates
        .filter((ed) => ed.eventId === activeEvent?._id)
        .sort((a, b) => (a.eventDate < b.eventDate ? -1 : 1))
        .map((date) => {
          return (
            <div key={date._id}>
              <div className="flex items-center justify-between font-bold bg-light-blue h-[50px] px-5">
                <p className="text-base">
                  {dateToLocaleString(date.eventDate)}
                </p>
                <div className="flex">
                  {!!!shifts.filter((shift) => shift.eventDateId === date._id)
                    .length && (
                    <button
                      className="flex items-center text-red font-bold mr-4"
                      type="button"
                      onClick={() => {
                        setLoading(true);
                        removeDate(date._id);
                      }}
                    >
                      <TrashIcon height={20}></TrashIcon>
                      Datum verwijderen
                    </button>
                  )}
                  <button
                    className="flex items-center text-blue font-bold mr-4"
                    type="button"
                    onClick={() => {
                      setDuplicateId(date._id.toString());
                      // @ts-ignore
                      document.getElementById("duplicateModal")?.showModal();
                    }}
                  >
                    <DuplicateIcon height={20}></DuplicateIcon> Dupliceer
                  </button>
                  <button
                    className="flex items-center text-blue font-bold mr-4"
                    type="button"
                    onClick={() => {
                      // check if this dat is in the past
                      if (
                        new Date(date.eventDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0)
                      ) {
                        // @ts-ignore
                        document.getElementById("lateEditError").showModal();
                        return;
                      }

                      setActiveDate(date);

                      // @ts-ignore
                      document.getElementById("addShift").showModal();
                    }}
                  >
                    <PlusCircleIcon height={20} />
                    Shift toevoegen
                  </button>
                  <button
                    id={date._id}
                    className="flex items-center text-blue font-bold disabled:opacity-50 mr-4"
                    onClick={(e) => {
                      //@ts-ignore
                      document.getElementById("sendInviteModal")?.showModal();
                      setActiveDate(date);
                    }}
                  >
                    <PaperAirplaneIcon height={20} />
                    Medewerkers uitnodigen
                  </button>
                  <button
                    className="flex items-center text-blue font-bold disabled:opacity-50"
                    disabled={
                      allocations.filter((alloc) => alloc.dateId === date._id)
                        .length === 0 ||
                      allocations.filter(
                        (alloc) =>
                          alloc.dateId === date._id &&
                          alloc.status === "pending"
                      ).length === 0
                    }
                    onClick={() => {
                      setLoading(true);
                      verifyEmployees(date);
                    }}
                  >
                    <BadgeCheckIcon height={20} />
                    Bevestigingsmails verzenden
                  </button>
                </div>
              </div>

              {shifts
                .filter((shift) => shift.eventDateId === date._id)
                .map((shift) => {
                  return (
                    <div
                      className="flex justify-between items-center py-5 hover:bg-background-grey"
                      key={shift._id}
                    >
                      <div className="px-5 w-1/2 flex ">
                        <div className="flex-1">{shift.shiftName}</div>
                        <div className="flex-1">{shift.shiftStart}</div>
                        <div className="flex-1">{shift.shiftEnd}</div>
                        <div className="flex-1">
                          {shift.breakStart ? shift.breakStart : "(geen)"}
                        </div>
                        <div className="flex-1">
                          {shift.breakEnd ? shift.breakEnd : "(geen)"}
                        </div>
                        <div className="flex-1">{shift.employeeAmount}</div>
                      </div>

                      <div className="px-5 w-1/2 flex justify-between ">
                        <Allocations date={date} shift={shift}></Allocations>
                        <div className="flex h-[41px]">
                          <button
                            className="text-red ml-4"
                            data-for="buttonTitle"
                            data-tip="Shift info aanpassen"
                            onClick={() => {
                              setActiveShiftForInfoModal(shift);
                              document
                                .getElementById("updateShiftInfoModal")
                                // @ts-ignore
                                ?.showModal();
                            }}
                          >
                            <PencilIcon height={20}></PencilIcon>
                          </button>
                          <button
                            className="text-red ml-5"
                            data-for="buttonTitle"
                            data-tip="Shift verwijderen"
                            onClick={() => {
                              setLoading(true);
                              removeShift(shift._id);
                            }}
                          >
                            <TrashIcon height={20}></TrashIcon>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default EventDate;
