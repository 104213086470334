import dayjs from "dayjs";
import { FormEvent, useEffect, useState } from "react";
import useStore from "../store/store";
import dialogPolyfill from "../utils/diaglog-polyfill";

export type AddShiftFormDataType = {
  shiftName: string;
  startHour: string;
  endHour: string;
  breakStart: string;
  breakEnd: string;
  shiftInfo: string;
  employeeAmount: number;
};
const emptyForm: AddShiftFormDataType = {
  shiftName: "",
  startHour: "",
  endHour: "",
  breakStart: "",
  breakEnd: "",
  shiftInfo: "",
  employeeAmount: 1,
};
const AddShiftModal = ({}) => {
  const { addShift, activeDate, activeEvent } = useStore();
  const [formData, setFormData] = useState(emptyForm);

  useEffect(() => {
    var dialog = document.querySelector("#addShift");
    //@ts-ignore
    dialogPolyfill.registerDialog(dialog);
  }, []);

  const clearStartTimeError = () => {
    const rrnrEl: HTMLInputElement | null = document.querySelector(
      "input[type=time]#startHour"
    );
    rrnrEl?.setCustomValidity("");
  };

  const setStartTimeError = () => {
    const rrnrEl: HTMLInputElement | null = document.querySelector(
      "input[type=time]#startHour"
    );
    rrnrEl?.setCustomValidity(
      "Tijdstip moet minimaal 30 minuten in de toekomst liggen"
    );
    rrnrEl?.reportValidity();
  };

  const submitForm = async (e: FormEvent) => {
    addShift(formData);
    setFormData(emptyForm);
  };

  return (
    <dialog
      id="addShift"
      className="border-none rounded-xl py-5 px-10 min-w-[500px] fixed left-0 top-0 mt-0"
    >
      <div className="flex items-center w-full justify-between">
        <h3 className="uppercase font-bold">
          Shift toevoegen {activeEvent?.name}{" "}
          <span className="font-normal">({activeDate?.eventDate})</span>
        </h3>
        <button
          className="text-3xl ml-2"
          onClick={() => {
            setFormData(emptyForm);
            //@ts-ignore
            document.getElementById("addShift")?.close();
          }}
          type="reset"
        >
          &times;
        </button>
      </div>

      <form method="dialog" onSubmit={submitForm}>
        <div className="flex flex-col pt-2">
          <label htmlFor="shiftName">Shift naam*</label>
          <input
            type="text"
            name="shiftName"
            id="shiftName"
            className="form-input bg-grey border-none rounded-md"
            required
            value={formData.shiftName}
            onChange={(e) =>
              setFormData({ ...formData, shiftName: e.target.value })
            }
            maxLength={50}
          />
        </div>
        <div className="flex flex-col pt-2">
          <label htmlFor="">Aantal medewerkers*</label>
          <input
            type="number"
            name="employeeAmount"
            id="employeeAmount"
            className="form-input bg-grey border-none rounded-md"
            required
            value={formData.employeeAmount}
            min={1}
            onChange={(e) =>
              setFormData({
                ...formData,
                employeeAmount: parseInt(e.target.value),
              })
            }
          />
        </div>
        <div className="flex justify-center gap-5 pt-2">
          <div className="flex flex-col flex-1">
            <label htmlFor="startHour">Startuur*</label>
            <input
              type="time"
              name="startHour"
              className="form-input bg-grey border-none rounded-md"
              id="startHour"
              required
              value={formData.startHour}
              onChange={(e) =>
                setFormData({ ...formData, startHour: e.target.value })
              }
              onBlur={(e) => {
                if (
                  dayjs().add(30, "minutes") >
                  dayjs(`${activeDate?.eventDate}T${e.target.value}:00`)
                ) {
                  setStartTimeError();
                } else {
                  clearStartTimeError();
                }
              }}
            />
          </div>
          <div className="flex flex-col  flex-1">
            <label htmlFor="endHour">Einduur*</label>
            <input
              type="time"
              name="endHour"
              className="form-input bg-grey border-none rounded-md"
              id="endHour"
              required
              value={formData.endHour}
              onChange={(e) =>
                setFormData({ ...formData, endHour: e.target.value })
              }
            />
          </div>
        </div>

        <div className="flex justify-center gap-5 pt-2">
          <div className="flex flex-col flex-1">
            <label htmlFor="breakStart">Pauze start</label>
            <input
              type="time"
              name="breakStart"
              className="form-input bg-grey border-none rounded-md"
              id="breakStart"
              value={formData.breakStart}
              onChange={(e) =>
                setFormData({ ...formData, breakStart: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="breakEnd">Pauze einde</label>
            <input
              type="time"
              className="form-input bg-grey border-none rounded-md"
              name="breakEnd"
              id="breakEnd"
              value={formData.breakEnd}
              onChange={(e) =>
                setFormData({ ...formData, breakEnd: e.target.value })
              }
            />
          </div>
        </div>

        <div className="flex flex-col pt-2">
          <label htmlFor="shiftInfo">Meer informatie voor de medewerkers</label>
          <textarea
            name="shiftInfo"
            className="form-input bg-grey border-none rounded-md resize-none"
            id="shiftInfo"
            rows={4}
            value={formData.shiftInfo}
            onChange={(e) =>
              setFormData({ ...formData, shiftInfo: e.target.value })
            }
          ></textarea>
        </div>
        <div className="flex justify-end pt-4">
          <button className="btn-primary mr-4">Toevoegen</button>
          <button
            className="btn-secondary"
            type="button"
            onClick={() => {
              setFormData(emptyForm);
              //@ts-ignore
              document.getElementById("addShift")?.close();
            }}
          >
            Annuleren
          </button>
        </div>
      </form>
    </dialog>
  );
};

export default AddShiftModal;
