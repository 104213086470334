import useStore from "../store/store";

const EventListBar = () => {
  const { events, activeEvent, setActiveEvent } = useStore();

  return (
    <div className="mt-5 border-solid border-b-2">
      <ul className="container mx-auto flex">
        {events.map((eventObj) => {
          return (
            <li key={eventObj._id}>
              <button
                onClick={() => setActiveEvent(eventObj)}
                className={
                  activeEvent?._id === eventObj._id
                    ? "bg-red text-white font-bold rounded-t-lg p-4 mx-0.5 whitespace-nowrap"
                    : "bg-light-blue text-blue font-bold rounded-t-lg p-4 mx-0.5 whitespace-nowrap"
                }
              >
                {eventObj.name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EventListBar;
